<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiCalendarCheck }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <v-btn color="accent" rounded @click.prevent="$router.push({ name: 'addnewappointment' })">
        <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
        <span>New Appointment</span>
      </v-btn>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> Today </v-btn>
            <v-btn fab text small color="black darken-2" @click="prev">
              <v-icon small> {{ icons.mdiChevronLeft }} </v-icon>
            </v-btn>
            <v-btn fab text small color="black darken-2" @click="next">
              <v-icon small>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> {{ icons.mdiMenuDown }} </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="accent"
            :events="events"
            @click:event="showEvent"
            :event-color="getEventColor"
            :type="type"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            class="my-2"
            :activator="selectedElement"
            offset-y
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color">
                <v-btn color="secondary" icon @click.prevent="editAppointment(selectedEvent)">
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
                <v-toolbar-title class="white--text">{{ selectedEvent.name }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn color="secondary" icon>
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <p class="mt-3">
                  <v-icon class="mr-1" color="secondary">{{ icons.mdiAlarm }}</v-icon
                  >{{ formatDate(selectedEvent.start) + ' ' + formatDate(selectedEvent.end) }}
                </p>
                <p>
                  <v-icon color="secondary" class="mr-1">{{ icons.mdiAccountTie }}</v-icon
                  >{{ selectedEvent.customer_name }}
                </p>
                <p>
                  <v-icon color="secondary" class="mr-1">{{ icons.mdiCar }}</v-icon
                  >{{ selectedEvent.vehicle_name }}
                </p>
                <p>
                  <v-icon color="secondary" class="mr-1">{{ icons.mdiCardAccountDetails }}</v-icon
                  >{{ selectedEvent.estimate_order }}
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="selectedOpen = false"> OK </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  mdiMenuDown,
  mdiPlus,
  mdiDotsVertical,
  mdiPencil,
  mdiChevronRight,
  mdiChevronLeft,
  mdiCalendarCheck,
  mdiAlarm,
  mdiAccountTie,
  mdiCardAccountDetails,
  mdiCar,
} from '@mdi/js'
export default {
  name: 'Calendar',
  components: {},

  mounted() {
    this.$refs.calendar.checkChange()
    this.getAppointments()
  },

  data() {
    return {
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],

      icons: {
        mdiMenuDown,
        mdiChevronRight,
        mdiChevronLeft,
        mdiCalendarCheck,
        mdiPlus,
        mdiPencil,
        mdiDotsVertical,
        mdiAlarm,
        mdiAccountTie,
        mdiCardAccountDetails,
        mdiCar,
      },
    }
  },

  methods: {
    getAppointments: function () {
      this.$store
        .dispatch(`appointment/getAppointments`)
        .then(response => ((this.events = this.Events), response))
        .catch(err => console.log(err))
    },

    editAppointment: function (selectedEvent) {
      this.$router.push({
        name: 'editappointment',
        params: { id: selectedEvent.id },
      })
    },

    viewDay: function ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor: function (event) {
      return event.color
    },
    setToday: function () {
      this.focus = ''
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
      }
      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }
    },
    prev: function () {
      this.$refs.calendar.prev()
    },
    next: function () {
      this.$refs.calendar.next()
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },
  },

  computed: {
    ...mapGetters({
      Events: 'appointment/fetchAppointments',
      UpcomingAppointments: 'appointment/fetchUpcomingAppointments',
      PreviousAppointments: 'appointment/fetchPreviousAppointments',
    }),
  },
}
</script>

<style>
</style>